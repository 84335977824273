<template>
  <v-container
    class="products-list-page"
    fluid>
    <v-row>
      <v-col cols="4">
        <v-row
          class="elevation-3 rounded products-row"
          style="height: 100%;">
          <v-col cols="12">
            <side-bar
              :products="products"
              :fetching-product="gettingProducts"
              @add-product="addProductToSelected($event)"
              @search="getProducts($event)" />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="8">
        <v-row
          class="elevation-3 rounded products-row"
          style="height: 100%;">
          <v-col cols="12">
            <div class="d-flex flex-row align-right">
              <h2>RFID Generator</h2>
              <v-spacer></v-spacer>
              <v-btn
                outlined
                class="mr-2"
                color="secondary"
                :loading="exporting"
                @click="dialogImport = true">
                IMPORT FILE
              </v-btn>
              <v-dialog
                v-model="dialogImport"
                max-width="500px"
                @click:outside="onCloseCsvDialog">
                <v-card>
                  <v-card-title>Upload a File</v-card-title>

                  <v-card-text class="pb-0">
                    <p>คำเตือนเมื่อทำการเลือกไฟล์และคอนเฟิร์มรายการที่เลือกไว้ก่อนหน้าจะหายไป</p>
                    <v-file-input
                      v-model="selectedFile"
                      label="Select CSV file"
                      accept=".csv"
                      color="secondary"
                      class="my-2"
                      outlined
                      dense
                      hide-details
                      @focus="clearValidate"
                      @change="validateCsv" />
                    <p
                      v-if="csvValidateErrors.length"
                      class="text-bold">
                      Total {{ csvValidateErrors.length }} errors.
                    </p>
                    <div
                      v-if="csvValidateErrors.length"
                      class="csv-fail">
                      <p
                        v-for="(errText, index) in csvValidateErrors"
                        :key="index"
                        class="red--text">
                        {{ errText }}
                      </p>
                    </div>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      @click="onCloseCsvDialog">
                      Close
                    </v-btn>
                    <v-btn
                      color="secondary"
                      :disabled="csvValidateErrors.length > 0"
                      @click="addProductFromCsv">
                      Confirm
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-btn
                color="secondary"
                :loading="exporting"
                :disabled="!selectedProduct.length"
                @click="onExport()">
                EXPORT
              </v-btn>
            </div>
            <v-divider class="my-4" />
            <div
              class="d-flex flex-column"
              style="gap: 16px; overflow-y: auto; max-height: calc(100vh - 220px);">
              <ItemBox
                v-for="(p, i) in selectedProduct"
                :key="`selected-${i}`"
                v-bind="p"
                :amount.sync="p.amount"
                :is-show-fit="isShowFit"
                @remove-item="removeItem(i)" />
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { ExportToCsv } from 'export-to-csv'
// import { generateQRCode } from '@/assets/js/helper'
import RFIDGenerator from '@/assets/js/RFIDGenerate'
import ConsumerTypes from '@/assets/js/ConsumerTypes'
import ProductProvider from '@/resources/ProductProvider'
import StoreProvider from '@/resources/StoreProvider'
import SideBar from '../components/sideBar.vue'
import ItemBox from '../components/itemBox.vue'

const StoreService = new StoreProvider()
const ProductService = new ProductProvider()
const fileReader = new FileReader()

export default {
  components: {
    SideBar,
    ItemBox
  },
  data () {
    return {
      gettingProducts: false,
      exporting: false,
      selectedProduct: [],
      products: [],
      webUrl: '',
      masterHeaderCsv: [
        'aliasId',
        'name',
        'amount',
        'code',
        'collection',
        'color',
        'size',
        'prototypeCategory',
        'productionDate',
        'price',
        'tags'
      ],
      dialogImport: false,
      selectedFile: null,
      csvValidateErrors: []
    }
  },
  computed: {
    ...mapGetters({
      store: 'Store/store'
    }),
    isShowFit () {
      return this.store?.id === '645a1d00765d5519beeb97f6'
    }
  },
  async mounted () {
    await this.getProducts()
    await this.getWebUrl()
  },
  methods: {
    ...mapActions({
      setErrorPage: 'Components/setErrorPage',
      setSnackbar: 'Components/setSnackbar'
    }),
    async getProducts (search = '') {
      if (this.gettingProducts) {
        return
      }

      try {
        this.gettingProducts = true

        const { data } = await ProductService.getProducts({ search })
        this.products = this.mappingSkus(data.results)
      } catch (error) {
        console.error('getting product', error)
        this.setErrorPage(error.code)
      } finally {
        this.gettingProducts = false
      }
    },
    mappingSkus (products) {
      const allSkus = []
      products.forEach((product) => {
        const tempSkus = product.skus.map((sku) => ({
          ...sku,
          name: product.name,
          model: product.model,
          collection: product.gwCollection,
          prototypeCategory: product.productPrototype?.category || 'UNKNOWN',
          tags: product.tags
        }))

        allSkus.push(...tempSkus)
      })

      return allSkus.map((sku) => ({
          aliasId: sku.aliasId,
          code: sku.code,
          name: sku.name,
          collection: sku.collection?.nickname ? sku.collection?.nickname : 'COLLECTION : -',
          prototypeCategory: sku.prototypeCategory,
          size: sku.size?.name || '',
          color: sku.color?.name || '',
          price: sku.price,
          images: sku.images,
          fitting: this.getFittingText(sku.tags)
        }))
    },
    getFittingText (tags) {
      if (this.isShowFit) {
        if (tags && tags.length) {
          const fittingTag = tags.find((tag) => ['UNISEX', 'WOMEN', 'KIDS', 'MEN'].includes(tag.toUpperCase()))
          if (fittingTag) {
            return fittingTag.toUpperCase()
          }
        }
        return 'FIT : -'
      }
      return ''
    },
    initOptions (size, color) {
      let option = ''

      if (size.name && color.name) {
        option = `${size.name.toUpperCase()} • ${color.name}`
      } else if (size.name && !color.name) {
        option = size.name.toUpperCase()
      } else if (!size.name && color.name) {
        option = color.name
      }

      return option
    },
    addAmount (index) {
      this.selectedProduct[index].amount++
    },
    removeAmount (index) {
      if (this.selectedProduct[index].amount > 1) {
        this.selectedProduct[index].amount--
      }
    },
    avoidNullValue (object, properties, defaultValue = '-') {
      return object[properties] || defaultValue
    },
    async getWebUrl () {
      const { data: { webUrl } } = await StoreService.getStoreSetting()
      this.webUrl = webUrl
    },
    isNumberString (str) {
      return /^\d+$/.test(str)
    },
    isMonthYearString (str) {
      const regex = /^(0[1-9]|1[0-2])-\d{4}$/
      return regex.test(str)
    },
    onCloseCsvDialog () {
      this.dialogImport = false
      this.clearValidate()
    },
    clearValidate () {
      this.selectedFile = null
      this.csvValidateErrors = []
    },
    areArraysEqual (arr1, arr2) {
      if (arr1.length !== arr2.length) return false
      const sorted1 = [...arr1].sort()
      const sorted2 = [...arr2].sort()
      return sorted1.every((val, index) => val === sorted2[index])
    },
    findDuplicates (arr) {
      return arr.filter((item, index) => arr.indexOf(item) !== index)
    },
    validateCsv () {
      this.csvValidateErrors = []
      if (!this.selectedFile) return
      fileReader.readAsText(this.selectedFile, 'utf8')
      fileReader.onload = async (readerEvent) => {
        try {
          const contents = readerEvent.target.result.split('\n')
          const elements = []
          contents.forEach((line) => {
            if (line.trim().length > 0) {
              const text = line
                .replaceAll('"', '')
                .replaceAll('\r', '')
                .split(',')
              if (!text.every((col) => col.trim() === '')) {
                elements.push(text)
              }
            }
          })
          if (!elements || !Array.isArray(elements) || !elements.length || elements.length === 1) {
            this.csvValidateErrors.push('The CSV contains no content. Please ensure the file has valid data.')
            return
          }

          const headerFields = elements[0].filter((h) => h)

          // คอลัมน์ไม่เกินแต่ field ไม่ถูก
          if (!this.areArraysEqual(this.masterHeaderCsv, headerFields)) {
            // เกินหาว่าอะไรเกิน
            const extraHeaders = headerFields.filter((h) => !this.masterHeaderCsv.includes(h))
            if (extraHeaders.length) {
              this.csvValidateErrors.push(
                `Header : The CSV contains more header fields than required: ${extraHeaders.join(', ')}. Please remove the extra fields`
              )
            }

            // ขาดหาว่าอะไรขาด
            const missingHeaders = this.masterHeaderCsv.filter((h) => !headerFields.includes(h))
            if (missingHeaders.length) {
              this.csvValidateErrors.push(`Header : Required headers are missing: ${missingHeaders.join(', ')}. Please add them.`)
            }

            // หาว่าอะไรซ้ำ
            const duplicateField = this.findDuplicates(headerFields)
            if (duplicateField.length) {
              this.csvValidateErrors.push(`Header : ${duplicateField.join(', ')} fields are duplicated`)
            }
            return
          }

          const addRowText = (existingText, newText) => `${existingText ? ', ' : ''} ${newText}`
          for (const [index, element] of elements.entries()) {
            if (index !== 0) {
              const productInfo = Object.fromEntries(headerFields.map((key, mapIndex) => [key, element[mapIndex]]))
              let rowFailMsg = ''
              if (!productInfo['price'] || !this.isNumberString(productInfo['price'])) {
                rowFailMsg += addRowText(rowFailMsg, 'price')
              }
              if (!productInfo['aliasId'] || !this.isNumberString(productInfo['aliasId'])) {
                rowFailMsg += addRowText(rowFailMsg, 'aliasId')
              }
              if (!productInfo['amount'] || !this.isNumberString(productInfo['amount'])) {
                rowFailMsg += addRowText(rowFailMsg, 'amount')
              }
              if (!productInfo['code']) rowFailMsg += addRowText(rowFailMsg, 'code')
              if (!productInfo['color']) rowFailMsg += addRowText(rowFailMsg, 'color')
              if (!productInfo['size']) rowFailMsg += addRowText(rowFailMsg, 'size')
              if (!productInfo['prototypeCategory']) rowFailMsg += addRowText(rowFailMsg, 'prototypeCategory')
              if (!productInfo['productionDate'] || !this.isMonthYearString(productInfo['productionDate'])) {
                rowFailMsg += addRowText(rowFailMsg, 'productionDate')
              }
              if (rowFailMsg) {
                rowFailMsg = `Row ${index + 1} : ${rowFailMsg} must be a valid \n`
                this.csvValidateErrors.push(rowFailMsg)
              }
            }
          }
        } catch (e) {
          this.csvValidateErrors.push('Something wrong on CSV validate')
        }
      }
    },
    async addProductFromCsv () {
      this.exporting = true
      if (!this.selectedFile) {
        this.setSnackbar({
          value: true,
          message: 'กรุณาเลือกไฟล์',
          type: 'error',
          timeout: 6000
        })
        this.exporting = false
        return
      }
      this.selectedProduct = []
      fileReader.readAsText(this.selectedFile, 'utf8')
      fileReader.onload = async (readerEvent) => {
        try {
          const contents = readerEvent.target.result.split('\n')
          const products = []
          contents.forEach((line) => {
            if (line.trim().length > 0) {
              const text = line
                .replaceAll('"', '')
                .replaceAll('\r', '')
                .split(',')
              if (!text.every((col) => col.trim() === '')) {
                products.push(text)
              }
            }
          })

          const csvHeaderAsArray = products[0]
          const indexOfTagField = csvHeaderAsArray.findIndex((headerText) => headerText === 'tags')
          for (const [index, productAsArray] of products.entries()) {
            if (index !== 0) {
              const productInfo = Object.fromEntries(csvHeaderAsArray.map((key, mapIndex) => [key, productAsArray[mapIndex]]))
              const tags = []
              if (indexOfTagField !== -1) {
                const tagLength = (productAsArray.length - csvHeaderAsArray.length) + 1
                for (let tagIndex = indexOfTagField; tagIndex < (indexOfTagField + tagLength); tagIndex++) {
                  const tag = productAsArray[tagIndex].trim()
                  if (tag) {
                    tags.push(tag)
                  }
                }
              }

              productInfo['fitting'] = this.getFittingText(tags)
              productInfo['price'] = Number(productInfo['price'])
              productInfo['aliasId'] = Number(productInfo['aliasId'])
              productInfo['amount'] = Number(productInfo['amount'])
              productInfo['collection'] = productInfo['collection'] ? productInfo['collection'] : 'COLLECTION : -'
              const consumerType = productInfo.prototypeCategory
              const url = `${(this.webUrl)}/${productInfo.code}`
              const product = {
                ...productInfo,
                url,
                consumerType: ConsumerTypes(consumerType.toLowerCase())
              }
              const duplicated = this.selectedProduct.findIndex((selected) => selected.code === product.code)
              if (duplicated === -1) {
                this.selectedProduct.push({ ...product })
              } else {
                this.selectedProduct[duplicated].amount += productInfo.amount
              }
            }
          }
        } catch (error) {
          this.setSnackbar({
            value: true,
            message: 'ไฟล์ไม่สามารถใช้งานได้กรุณาตรวจสอบ',
            type: 'error',
            timeout: 6000
          })
        } finally {
          this.exporting = false
        }
      }
      this.dialogImport = false
      this.selectedFile = null
    },
    async addProductToSelected (_product) {
      // const qr = await generateQRCode(`${(this.webUrl)}/${_product.code}`)
      if (this.isShowFit && _product.fitting === 'FIT : -') {
        this.setSnackbar({
          value: true,
          message: 'รายการสินค้านี้ไม่ได้ระบุ Fit กรุณาตรวจสอบ',
          type: 'error',
          timeout: 6000
        })
      }
      const url = `${(this.webUrl)}/${_product.code}`
      const product = {
        ..._product,
        url,
        consumerType: ConsumerTypes(_product.prototypeCategory.toLowerCase()),
        productionDate: this.$dayjs().subtract(2, 'month').format('MM-YYYY')
      }
      const duplicated = this.selectedProduct.findIndex((selected) => selected.code === product.code)
      if (duplicated === -1) {
        this.selectedProduct.push({ ...product, amount: 1 })
      } else {
        this.selectedProduct[duplicated].amount++
      }
    },
    removeItem (index) {
      this.selectedProduct.splice(index, 1)
    },
    async onExport () {
      try {
        this.exporting = true
        const mapped = this.selectedProduct.map((v) => ({
            aliasId: v.aliasId,
            amount: v.amount,
            code: v.code,
            collection: v.collection,
            color: v.color,
            size: v.size,
            url: v.url,
            consumerType: v.consumerType,
            productionDate: v.productionDate,
            price: `${v.price.toLocaleString()} THB`,
            fitting: v.fitting
          }))
        const rfid = new RFIDGenerator(mapped)
        const generated = await rfid.generateWithBatch()
        const timeStringFormat = this.$dayjs().format('DD_MM_YYYY_HHmm')
        const options = {
          filename: `${this.store.uniqueCode}_RFID_${timeStringFormat}`,
          showLabels: true,
          useKeysAsHeaders: true
        }

        const csvExporter = new ExportToCsv(options)
        csvExporter.generateCsv(generated)
      } catch (error) {
        console.error('exportCsv', error)
        this.$store.dispatch('Components/setSnackbar', {
          value: true,
          message: error?.message || error,
          type: 'error'
        })
      } finally {
        this.exporting = false
      }
    }
  }
}
</script>

<style scoped>
.rfid-table {
  height: calc(100vh - 190px);
}
.csv-fail {
  max-height: 200px;
  overflow-y: scroll;
}
</style>
